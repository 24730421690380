import { sendRequest, handleSessionChange, getJSON } from "./utils";
import { FetchConfig } from "./types";

const createFetchHandler = (url: string, config: FetchConfig) => async () => {
  const response = await sendRequest(url, config);
  const json = await getJSON(response);
  await handleSessionChange(json);
  return json;
};

export default createFetchHandler;
