import { useState, useCallback } from "react";
import mem from "mem";
import Auth from "../auth/index";
import httpMethods from "../utils/http";
import defaultCreateFetchHandler from "../createFetchHandler";
import { buildURL } from "../utils";
import { RuntimeFetchArgs, FetchArgs } from "../types";

const root =
  process.env.STASH_API_PATH || process.env.REACT_APP_STASH_API_PATH || "";

const defaults = {
  root,
  method: httpMethods.GET
} as const;

const getConfig = (userConfig: Partial<FetchArgs>) => {
  return Object.assign({}, defaults, userConfig);
};

const memoizeConfig = mem(getConfig, {
  cacheKey: arguments_ => JSON.stringify(arguments_)
});

export function useFetch({
  createFetchHandler = defaultCreateFetchHandler,
  ...userConfig
}: Partial<FetchArgs>) {
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<Boolean>(false);

  const memoizedConfig = memoizeConfig(userConfig);

  const fetch = useCallback(
    async (runtimeFetchArgs?: RuntimeFetchArgs) => {
      const config = {
        ...memoizedConfig,
        ...runtimeFetchArgs
      };
      const url = buildURL(config, Auth.get());
      const fetchHandler = createFetchHandler(url, config);

      setLoading(true);
      try {
        const data = await fetchHandler();
        setData(data);
        setLoading(false);
        return data;
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    },
    [memoizedConfig, createFetchHandler]
  );

  return {
    fetch,
    data,
    error,
    loading
  };
}

useFetch.POST = httpMethods.POST;
useFetch.PUT = httpMethods.PUT;
useFetch.PATCH = httpMethods.PATCH;
useFetch.GET = httpMethods.GET;
useFetch.DELETE = httpMethods.DELETE;
useFetch.HEAD = httpMethods.HEAD;
useFetch.OPTIONS = httpMethods.OPTIONS;
